.deskripsi {
  z-index: 0;
  margin-top: 60px;
  margin-left: 100px;
  margin-right: 100px;
  text-align: center;
}
.deskrip0 {
  font-weight: bolder;
  padding-top: 20px;
}
.accordion-button {
  background-color: #ffd54f !important;
}
.accordion-item {
  background-color: #fff176;
}

@media only screen and (max-width: 768px) {
  .deskripsi {
    z-index: 0;
    margin-top: 30px;
    margin-left: 20px;
    margin-right: 20px;
    text-align: center;
  }
}
