.navbar {
  background-color: yellow;
  z-index: 1;
}
.logoLP {
  width: 50px;
  height: 50px;
  color: blue;
}
.nav-item {
  width: 190px;
}
nav > div > .show {
  width: 600px;
  z-index: 1000;
  left: 0;
  top: 0;
  height: 100vh;
}
.dropdown-menu {
  background-color: yellow;
}

@media only screen and (max-width: 768px) {
}
