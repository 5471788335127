.menuutama {
  z-index: 0;
  margin-top: 60px;
  justify-content: center;
  align-items: center;
}
.cardmenu {
  width: 30%;
}
.btnmnutm {
  width: 100%;
}

@media only screen and (max-width: 768px) {
  .menuutama {
    z-index: 0;
    margin-top: 30px;
    font-size: xx-small;
  }
  .cardmenu {
    width: 30%;
    text-align: center;
  }
  .card-title {
    font-size: x-small;
    height: 40px;
    font-weight: bolder;
  }
  .card-text {
    height: 50px;
  }
  .btnmnutm {
    font-size: x-small;
    height: 60px;
  }
}
