.footer {
  z-index: -10;
  display: flex;
  flex-direction: row;
  margin: 50px;
  padding-left: 30px;
  padding-bottom: 30px;
}
.yogaleft {
  width: 60%;
  margin-left: 120px;
  display: flex;
  flex-direction: column;
  text-align: center;
}
.yogaright {
  width: 40%;
}
.yogalink {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  margin: 12px;
}
.yogalinkpic {
  width: 36px;
  height: 36px;
  filter: drop-shadow(10px 10px 5px yellow);
}
.buttonmail {
  background: none !important;
  border: none !important;
}
.whatsappbutton {
  border: none;
  background: none;
}

/* yogaintroduce */
@media only screen and (max-width: 768px) {
  .footer {
    width: 100%;
    margin-left: 0px;
    margin-top: 12px;
    padding-top: 20px;
    padding-left: 0px;
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
  }
  .yogaright {
    width: 100%;
    padding-left: 30px;
    padding-right: 30px;
  }
  .yogaleft {
    width: 90%;
    margin: 0px;
    padding-top: 20px;
    display: flex;
  }
}
